//
//  /* FOOTER */
//


.footer {
    background: none repeat scroll 0 0 var(--#{$prefix}secondary-bg);
    border-top: 1px solid var(--#{$prefix}border-color);
    bottom: 0;
    left: 0;
    padding: 10px 20px;
    position: absolute;
    right: 0;
}

.footer.fixed {
    position: fixed;
    bottom: 0;
    left: $sidebar-width;
    right: 0;
    z-index: 1000;
    padding: 10px 20px;
    background: var(--#{$prefix}secondary-bg);
    border-top: 1px solid var(--#{$prefix}border-color);
}

.mini-navbar {
    .footer.fixed {
        left: 70px;
    }
}

.boxed-layout {
    .footer.fixed {
        max-width: calc($boxed-width - $sidebar-width);
        margin: 0 auto;
    }

    &.mini-navbar {
        .footer.fixed {
            max-width: calc($boxed-width - 70px);
        }
    }
}