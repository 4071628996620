
/* PAGINATIN */

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
    //background-color: #f4f4f4;
    border-color: var(--#{$prefix}border-color);
    //color: inherit;
    cursor: default;
    z-index: 2;
}

.pagination>li>a,
.pagination>li>span {
    background-color: transparent;
    border: 1px solid var(--#{$prefix}border-color);
    color: inherit;
    float: left;
    line-height: 1.42857;
    margin-left: -1px;
    padding: 4px 10px;
    position: relative;
    text-decoration: none;
}

.page-item.active .page-link {
    background-color: $primary;
    border-color: $primary;
}

.page-link:focus {
    box-shadow: none;
}

.page-link:hover {
    color: $text-color;
}

.pagination .footable-page.active a {
    background-color: $primary;
    border-color: $primary;
    color: $white;
}
