/* PIN BOARD */
ul.notes li,
ul.tag-list li {
    list-style: none;
}

ul.notes li h4 {
    margin-top: 20px;
    font-size: 16px;
}

ul.notes li div {
    text-decoration: none;
    color: #000;
    background: #ffc;
    display: block;
    height: 140px;
    width: 140px;
    padding: 1em;
    position: relative;
}

ul.notes li div small {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 10px;
}

ul.notes li div a {
    position: absolute;
    right: 10px;
    bottom: 10px;
    color: inherit;
}

ul.notes li {
    margin: 10px 40px 50px 0;
    float: left;
}

ul.notes li div p {
    font-size: 12px;
}

ul.notes li div {
    text-decoration: none;
    color: #000;
    background: #ffc;
    display: block;
    height: 140px;
    width: 140px;
    padding: 1em;
    /* Firefox */
    -moz-box-shadow: 5px 5px 2px #212121;
    /* Safari+Chrome */
    -webkit-box-shadow: 5px 5px 2px rgba(33, 33, 33, 0.7);
    /* Opera */
    box-shadow: 5px 5px 2px rgba(33, 33, 33, 0.7);
}

ul.notes li div {
    -webkit-transform: rotate(-6deg);
    -o-transform: rotate(-6deg);
    -moz-transform: rotate(-6deg);
    -ms-transform: rotate(-6deg);
}

ul.notes li:nth-child(even) div {
    -o-transform: rotate(4deg);
    -webkit-transform: rotate(4deg);
    -moz-transform: rotate(4deg);
    -ms-transform: rotate(4deg);
    position: relative;
    top: 5px;
}

ul.notes li:nth-child(3n) div {
    -o-transform: rotate(-3deg);
    -webkit-transform: rotate(-3deg);
    -moz-transform: rotate(-3deg);
    -ms-transform: rotate(-3deg);
    position: relative;
    top: -5px;
}

ul.notes li:nth-child(5n) div {
    -o-transform: rotate(5deg);
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    -ms-transform: rotate(5deg);
    position: relative;
    top: -10px;
}

ul.notes li div:hover,
ul.notes li div:focus {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -o-transform: scale(1.1);
    -ms-transform: scale(1.1);
    position: relative;
    z-index: 5;
}

ul.notes li div {
    text-decoration: none;
    color: #000;
    background: #ffc;
    display: block;
    height: 210px;
    width: 210px;
    padding: 1em;
    -moz-box-shadow: 5px 5px 7px #212121;
    -webkit-box-shadow: 5px 5px 7px rgba(33, 33, 33, 0.7);
    box-shadow: 5px 5px 7px rgba(33, 33, 33, 0.7);
    -moz-transition: -moz-transform 0.15s linear;
    -o-transition: -o-transform 0.15s linear;
    -webkit-transition: -webkit-transform 0.15s linear;
}