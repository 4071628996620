// 
// _calendar.scss
// 

:root {
    --fc-border-color: var(--#{$prefix}border-color);
    --fc-page-bg-color: var(--#{$prefix}secondary-bg);
    --fc-neutral-bg-color: var(--#{$prefix}light);
}

.fc td,
.fc th {
    border: $table-border-width solid $table-border-color;
}

.fc {
    .fc-toolbar {
        h2 {
            font-size: 1.25rem !important;
            line-height: 1.875rem;
            text-transform: uppercase;
        }

        @media (max-width: 767.98px) {

            .fc-left,
            .fc-right,
            .fc-center {
                float: none;
                display: block;
                text-align: center;
                clear: both;
                margin: 10px 0;
            }

            >*>* {
                float: none;
            }

            .fc-today-button {
                display: none;
            }
        }

        .btn {
            text-transform: capitalize;
        }
    }

    .fc-col-header-cell-cushion {
        display: block;
        padding: 2px 4px;
        color: var(--#{$prefix}body-color);
    }

    .fc-daygrid-day-number {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        padding: 0;
        display: flex;
        background-color: var(--#{$prefix}tertiary-bg);
        align-items: center;
        justify-content: center;
        font-size: 11px;
        font-weight: $font-weight-medium;
        margin: 2px;
        color: var(--#{$prefix}body-color);
    }

    .fc-daygrid-day {
        &.fc-day-today {
            background-color: rgba($primary, 0.1);

            .fc-daygrid-day-number {
                background-color: $primary;
                color: $white;
            }
        }
    }

    .fc-daygrid-day {
        &.fc-day-today {
            background-color: rgba($primary, 0.1);
        }
    }

    .fc-timegrid-col {
        &.fc-day-today {
            background-color: rgba($primary, 0.1);
        }
    }

    .fc-list-event:hover td {
        background: transparent;
    }

    .fc-list-event-title {
        a {
            color: $white !important;
        }
    }

    .fc-col-header,
    .fc-daygrid-body,
    .fc-scrollgrid-sync-table {
        width: 100% !important;
    }

    .fc-scrollgrid-section>* {
        border-left: $table-border-width solid $table-border-color;
        border-top: $table-border-width solid $table-border-color;
    }

    .fc-scrollgrid {
        border: 0;
    }

    .fc-scrollgrid-section-liquid>td {
        border-top: 0;
    }

    .fc-multimonth {
        border-color: var(--#{$prefix}border-color);
    }
}

.fc-theme-bootstrap a:not([href]) {
    color: var(--#{$prefix}body-color);
}

.fc-event {
    color: $white !important;
}

.fc {
    th.fc-widget-header {
        background: $gray-300;
        line-height: 20px;
        padding: 10px 0;
        text-transform: uppercase;
        font-weight: $font-weight-bold;
    }
}

.fc-unthemed {

    .fc-content,
    .fc-divider,
    .fc-list-heading td,
    .fc-list-view,
    .fc-popover,
    .fc-row,
    tbody,
    td,
    th,
    thead {
        border-color: $gray-300;
    }

    td.fc-today {
        background: lighten($gray-200, 2%);
    }
}

.fc-button {
    background: var(--#{$prefix}primary);
    border-color: var(--#{$prefix}primary);
    color: $gray-700;
    text-transform: capitalize;
    box-shadow: var(--#{$prefix}btn-box-shadow) rgba(var(--#{$prefix}primary-rgb), .5);
    padding: 0.28rem 0.8rem !important;
    height: auto !important;
}

.fc .fc-multimonth-multicol .fc-daygrid-more-link {
    border: 1px solid $primary;
    display: block;
    float: none;
    padding: 1px;
    font-weight: $font-weight-medium;
    font-family: inherit;
}

.fc-state-down,
.fc-state-active,
.fc-state-disabled {
    background-color: $primary;
    color: $white;
    text-shadow: none;
}

.fc-event {
    border-radius: 2px;
    border: none;
    cursor: move;
    font-size: 0.8125rem;
    margin: 5px 7px;
    padding: 5px 5px;
    text-align: center;
}

.fc-theme-standard {
    .fc-popover {
        .fc-popover-header {
            padding: 8px 14px;
        }
    }
}

.fc-event,
.fc-event-dot {
    background-color: $primary;
}

.fc-daygrid-dot-event.fc-event-mirror,
.fc-daygrid-dot-event:hover {
    background-color: $primary;
}

.fc-event-title,
.fc-sticky {
    font-weight: $font-weight-semibold !important;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.fc-daygrid-event-dot {
    border-color: $white !important;
}


.fc-event .fc-content {
    color: $white;
}

#external-events {
    .external-event {
        text-align: left;
        padding: 8px 16px;
        margin: 6px 0;
    }
}


.fc-day-grid-event.fc-h-event.fc-event.fc-start.fc-end.bg-dark {
    .fc-content {
        color: $light;
    }
}


.fc-toolbar-chunk {
    .fc-button-group {
        box-shadow: var(--#{$prefix}box-shadow);

        .fc-button {
            color: $white;
            background-color: $primary;
            border: none;
            box-shadow: none;

            &:hover,
            &.active {
                color: $white;
                background-color: $primary;
            }
        }
    }

    .fc-today-button {
        background-color: $primary !important;
        border: $primary;
    }
}

.fc {

    .fc-button-primary:not(:disabled).fc-button-active,
    .fc-button-primary:not(:disabled):active {
        background-color: $primary !important;
        border-color: $primary !important;
        color: $white !important;
    }

    .fc-button-primary {
        text-transform: capitalize;
    }
}


.fc-toolbar {
    @media (max-width: 575.98px) {
        flex-direction: column;
        gap: 16px;
    }
}



.fc-daygrid-event-harness {
    .fc-daygrid-event {
        backdrop-filter: blur(4px);
    }
}

.fc-timegrid-event-harness {
    .fc-timegrid-event {
        backdrop-filter: blur(4px);
    }
}


.fc-timegrid-slots {
    table {
        tr {
            border-color: var(--#{$prefix}border-color) !important;
        }
    }
}

.fc-list-table {
    border-color: var(--#{$prefix}border-color);
}

@each $color,
$value in $theme-colors {
    .fc-daygrid-event-harness {
        .fc-daygrid-event {
            &.bg-#{$color}-subtle {

                .fc-event-title,
                .fc-event-main {
                    color: $value !important;
                }
            }
        }
    }

    .fc-timegrid-event-harness {
        .fc-timegrid-event {
            &.bg-#{$color}-subtle {
                .fc-event-title {
                    color: $value !important;
                }
            }
        }
    }

    .fc-list-table {
        .fc-list-event {
            &.bg-#{$color}-subtle {
                color: $value !important;

                .fc-list-event-title {
                    >a {
                        color: $value !important;
                    }
                }

                .fc-list-event-dot {
                    border-color: $value;
                }
            }
        }
    }
}

.fc-daygrid-event-harness .fc-daygrid-event.bg-dark-subtle {

    .fc-event-main,
    .fc-event-title {
        color: var(--#{$prefix}dark-text-emphasis) !important;
    }
}

.fc-direction-ltr {
    direction: ltr;

    .fc-toolbar>*>:not(:first-child) {
        margin-left: 0.75em;
    }
}

.fc-h-event .fc-event-main {
    columns: #fff !important;
}


/* FULLCALENDAR */

.fc-state-default {
    background-color: var(--#{$prefix}secondary-bg);
    background-image: none;
    background-repeat: repeat-x;
    box-shadow: none;
    color: #333333;
    text-shadow: none;
}

.fc-state-default {
    border: 1px solid;
}

.fc-button {
    color: inherit;
    border: 1px solid var(--#{$prefix}border-color);
    cursor: pointer;
    display: inline-block;
    height: 1.9em;
    line-height: 1.9em;
    overflow: hidden;
    padding: 0 0.6em;
    position: relative;
    white-space: nowrap;
}

.fc-state-active {
    background-color: $primary;
    border-color: $primary;
    color: var(--#{$prefix}secondary-bg);
}

.fc-header-title h2 {
    font-size: 16px;
    font-weight: 600;
    color: inherit;
}

.fc-content .fc-widget-header,
.fc-content .fc-widget-content {
    border-color: var(--#{$prefix}border-color);
    font-weight: normal;
}

.fc-border-separate tbody {
    background-color: #F8F8F8;
}

.fc-state-highlight {
    background: none repeat scroll 0 0 #FCF8E3;
}

.external-event {
    padding: 5px 10px;
    border-radius: 2px;
    cursor: pointer;
    margin-bottom: 5px;
}

.fc-ltr .fc-event-hori.fc-event-end,
.fc-rtl .fc-event-hori.fc-event-start {
    border-radius: 2px;
}

.fc-event,
.fc-agenda .fc-event-time,
.fc-event a {
    padding: 4px 6px;
    background-color: $primary;
    /* background color */
    border-color: $primary;
    /* border color */
}

.fc-event-time,
.fc-event-title {
    color: $white;
    padding: 0 1px;
}

.ui-calendar .fc-event-time,
.ui-calendar .fc-event-title,
.fc-event-container a.fc-event {
    color: $white;
}