// 
// 
// 

div.hopscotch-bubble {
    border: 3px solid $primary;
    border-radius: 5px;
    background-color: $card-bg;
    color: $body-color;

    h3 {
        color: var(--#{$prefix}dark);
    }


    .hopscotch-next,
    .hopscotch-prev {
        background-color: $primary !important;
        background-image: none !important;
        border-color: $primary !important;
        text-shadow: none !important;
        margin: 0 0 0 5px !important;
        font-family: $font-family-base;
        color: $white !important;
    }

    .hopscotch-bubble-number {
        background: $danger;
        padding: 0;
        border-radius: 50%;
    }

    .hopscotch-bubble-arrow-container {
        &.left {
            .hopscotch-bubble-arrow-border {
                border-right: 19px solid $primary;
            }

            .hopscotch-bubble-arrow {
                border: none;
            }
        }

        &.right {
            .hopscotch-bubble-arrow {
                border-left: 19px solid $primary;
                left: -2px;
            }

            .hopscotch-bubble-arrow-border {
                border-left: 0 solid $primary;
            }
        }

        &.up {
            .hopscotch-bubble-arrow {
                border-bottom: 19px solid $primary;
                top: 0;
            }

            .hopscotch-bubble-arrow-border {
                border-bottom: 0 solid rgba(0, 0, 0, .5);
            }
        }

        &.down {
            .hopscotch-bubble-arrow {
                border-top: 19px solid $primary;
                top: -2px;
            }

            .hopscotch-bubble-arrow-border {
                border-top: 0 solid rgba(0, 0, 0, .5);
            }
        }
    }

    h3 {
        font-family: $font-family-base;
        margin-bottom: 10px;
    }

    .hopscotch-content {
        color: var(--#{$prefix}body-color);
        font-family: $font-family-base;
    }
}


/* Tour */

.tour-tour .btn.btn-default {
    background-color: var(--#{$prefix}secondary-bg);
    border: 1px solid #d2d2d2;
    color: inherit;
}

.tour-step-backdrop {
    z-index: 2101;
}

.tour-backdrop {
    z-index: 2100;
    opacity: .7;
}

.popover[class*=tour-] {
    z-index: 2100;
}

.popover-header {
    margin-top: 0;
}

body.tour-open .animated {
    animation-fill-mode: initial;
}

.tour-tour .btn.btn-secondary {
    background-color: var(--#{$prefix}secondary-bg);
    border: 1px solid #d2d2d2;
    color: inherit;
}