//
// _lightbox.scss
//


.lightBoxGallery {
    text-align: center
}

.lightBoxGallery img {
    margin: 5px
}