/* E-commerce */

.product-box {
    padding: 0;

    border: 1px solid var(--#{$prefix}border-color);
}

.product-box:hover,
.product-box.active {
    border: 1px solid transparent;
    box-shadow: 0 3px 7px 0 var(--#{$prefix}border-color);
}

.product-imitation {
    text-align: center;
    padding: 90px 0;
    background-color: var(--#{$prefix}light);
    color: darken($gray, 20%);
    font-weight: 600;
}

.cart-product-imitation {
    text-align: center;
    padding-top: 30px;
    height: 80px;
    width: 80px;
    background-color: var(--#{$prefix}light);
}

.product-imitation.xl {
    padding: 120px 0;
}

.product-desc {
    padding: 20px;
    position: relative;
}

.ecommerce .tag-list {
    padding: 0;
}

.ecommerce .fa-star {
    color: $light-gray;
}

.ecommerce .fa-star.active {
    color: $yellow;
}

.ecommerce .note-editor {
    border: 1px solid var(--#{$prefix}border-color);
}

table.shoping-cart-table {
    margin-bottom: 0;

    tr td {
        border: none;
        text-align: right;
    }

    tr td.desc,
    tr td:first-child {
        text-align: left;
    }

    tr td:last-child {
        width: 80px;
    }

}

.product-name {
    font-size: 16px;
    font-weight: 600;
    color: var(--#{$prefix}body-color);
    display: block;
    margin: 2px 0 5px 0;
}

.product-name:hover,
.product-name:focus {
    color: $navy;
}

.product-price {
    font-size: 14px;
    font-weight: 600;
    color: var(--#{$prefix}secondary-bg);
    background-color: $navy;
    padding: 6px 12px;

    position: absolute;
    top: -32px;
    right: 0;
}

.product-detail .ibox-content {
    padding: 30px 30px 50px 30px;
}

.image-imitation {
    background-color: var(--#{$prefix}light);
    text-align: center;
    padding: 200px 0;

}

.product-main-price small {
    font-size: 10px;

}

.product-images {
    margin: 0 20px;
}

/* Payments */
.payment-card {
    background: var(--#{$prefix}secondary-bg);
    padding: 20px;
    margin-bottom: 25px;
    border: 1px solid var(--#{$prefix}border-color);
  }
  .payment-icon-big {
    font-size: 60px;
  }