/* PANELS */

.panel-title>.small,
.panel-title>.small>a,
.panel-title>a,
.panel-title>small,
.panel-title>small>a {
    color: inherit;
}

.page-heading {
    border-top: 0;
    padding: 0 10px 20px 10px;
}

.panel-heading h1,
.panel-heading h2 {
    margin-bottom: 5px;
}

.panel-body {
    padding: 15px;
}

/* Bootstrap 3.3.x panels */
.panel {
    margin-bottom: 20px;
    background-color: var(--#{$prefix}secondary-bg);
    border: 1px solid transparent;
    border-radius: 4px;
}

.panel-heading {
    color: white;
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.panel-footer {
    padding: 10px 15px;
    border-top: 1px solid var(--#{$prefix}border-color);
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.panel-default>.panel-heading {
    color: var(--#{$prefix}body-color);
    background-color: var(--#{$prefix}light);
    border-color: var(--#{$prefix}border-color);
}

.panel-default {
    border-color: var(--#{$prefix}border-color);
}

.panel-group .panel+.panel {
    margin-top: 5px;
}

.panel-group .panel {
    margin-bottom: 0;
    border-radius: 4px;
}


.panel-primary {
    border-color: $navy;
}

.panel-primary>.panel-heading {
    background-color: $navy;
    border-color: $navy;
}

.panel-success {
    border-color: $blue;
}

.panel-success>.panel-heading {
    background-color: $blue;
    border-color: $blue;
    color: $white;
}

.panel-info {
    border-color: $lazur;
}

.panel-info>.panel-heading {
    background-color: $lazur;
    border-color: $lazur;
    color: $white;
}

.panel-warning {
    border-color: $yellow;
}

.panel-warning>.panel-heading {
    background-color: $yellow;
    border-color: $yellow;
    color: $white;
}

.panel-danger {
    border-color: $red;
}

.panel-danger>.panel-heading {
    background-color: $red;
    border-color: $red;
    color: $white;
}

/* PANELS */

.panel.blank-panel {
    background: none;
    margin: 0;
}

.blank-panel .panel-heading {
    padding-bottom: 0;
}


.panel-title {
    font-size: inherit;
}

// ibox


.ibox {
    clear: both;
    margin-bottom: $grid-gutter-width;
    margin-top: 0;
    padding: 0;
}

.ibox.collapsed .ibox-content {
    display: none;
}

.ibox.collapsed .fa.fa-chevron-up:before {
    content: "\f078";
}

.ibox.collapsed .fa.fa-chevron-down:before {
    content: "\f077";
}

.ibox:after,
.ibox:before {
    display: table;
}

.ibox-title {
    background-color: $card-bg;
    border-color: var(--#{$prefix}border-color);
    border-image: none;
    border-style: solid solid none;
    border-width: 1px;
    color: inherit;
    margin-bottom: 0;
    padding: 15px 90px 8px 15px;
    min-height: 48px;
    position: relative;
    clear: both;
    border-radius: 2px 2px 0 0;
}

.ibox-content {
    background-color: $card-bg;
    color: inherit;
    padding: 15px 20px 20px 20px;
    border-color: var(--#{$prefix}border-color);
    border-image: none;
    border-style: solid;
    border-width: 1px;
}

.ibox-footer {
    color: inherit;
    border-top: 1px solid var(--#{$prefix}border-color);
    font-size: 90%;
    background: var(--#{$prefix}secondary-bg);
    padding: 10px 15px;
}



.ibox-content {
    clear: both;
}

.ibox-heading {
    background-color: $card-cap-bg;
    border-bottom: none;
}

.ibox-heading h3 {
    font-weight: 200;
    font-size: 24px;
}

.ibox-title h5 {
    display: inline-block;
    font-size: 15px;
    margin: 0 0 7px;
    padding: 0;
    text-overflow: ellipsis;
    float: none;
}

.ibox-title .label {
    margin-left: 4px;
}

.ibox-title .pull-right {
    position: absolute;
    right: 15px;
    top: 15px;
}

.ibox-tools {
    display: block;
    float: none;
    margin-top: 0;
    position: absolute;
    top: 12px;
    right: 15px;
    padding: 0;
    text-align: right;
}

.ibox-tools {

    a.collapse-link,
    a.dropdown-toggle,
    a.close-link {
        display: inline-block;
        height: 24px;
        width: 24px;
        line-height: 22px;
        text-align: center;
        border-radius: 50%;
        background-color: $card-cap-bg;
        color: $gray-700;
        transition: all 0.3s ease-out;

        i {
            vertical-align: middle;
        }

        &:hover {
            background-color: rgba(var(--#{$prefix}primary-rgb), 0.2);
            color: var(--#{$prefix}primary);
        }
    }
}

.ibox-tools a.btn-primary {
    color: #fff !important;
}

.ibox-tools .dropdown-menu>li>a {
    padding: 4px 10px;
    font-size: 12px;
    color: $text-color !important;
}

.ibox .ibox-tools.open>.dropdown-menu {
    left: auto;
    right: 0;
}

.ibox-tools .dropdown-toggle::after {
    display: none;
}

// 

.ui-draggable .ibox-title {
    cursor: move;
}

@media (max-width: 350px) {
    .ibox-tools {
        float: none;
        text-align: left;
        display: inline-block;
    }
}

.pull-right {
    float: right;
}