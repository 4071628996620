// Right sidebar



#right-sidebar {
    background-color: var(--#{$prefix}secondary-bg);
    border-left: 1px solid var(--#{$prefix}border-color);
    border-top: 1px solid var(--#{$prefix}border-color);
    overflow: hidden;
    position: fixed;
    top: 60px;
    width: 260px !important;
    z-index: 1009;
    bottom: 0;
    right: -260px;

    &.sidebar-open {
        right: 0;
    }

    &.sidebar-open.sidebar-top {
        top: 0;
        border-top: none;
    }
}


.sidebar-container {

    ul.nav-tabs {
        border: none;
    }

    ul.nav-tabs.navs-4 li {
        width: 25%;
    }

    ul.nav-tabs.navs-3 li {
        width: 33.3333%;
    }

    ul.nav-tabs.navs-2 li {
        width: 50%;
    }

    ul.nav-tabs li {
        border: none;
    }

    ul.nav-tabs li a {
        border: none;
        padding: 12px 10px;
        margin: 0;
        border-radius: 0;
        background: $nav-bg;
        color: $white !important;
        text-align: center;
        border-right: 1px solid lighten($nav-bg, 2%);

        &.active {
            color: var(--#{$prefix}nav-tabs-link-active-color) !important;
        }
    }

    ul.nav-tabs li.active a {
        border: none;
        background: var(--#{$prefix}light);
        color: $text-color;
        font-weight: bold;
    }

    .nav-tabs>li.active>a:hover,
    .nav-tabs>li.active>a:focus {
        border: none;
    }

    ul.sidebar-list {
        margin: 0;
        padding: 0;
    }

    ul.sidebar-list li {
        border-bottom: 1px solid var(--#{$prefix}border-color);
        padding: 15px 20px;
        list-style: none;

        font-size: 12px;
    }

    .sidebar-message:nth-child(2n+2) {
        background: var(--#{$prefix}light);
    }

    ul.sidebar-list li a {
        text-decoration: none;
        color: inherit;
    }

    .sidebar-content {
        padding: 15px 20px;
        font-size: 12px;
    }

    .sidebar-title {
        background: var(--#{$prefix}light);
        padding: 20px;
        border-bottom: 1px solid var(--#{$prefix}border-color);

        h3 {
            margin-bottom: 3px;
            padding-left: 2px;
        }
    }

    .tab-content {
        h4 {
            margin-bottom: 5px;
        }
    }

    .sidebar-message>a>.float-start {
        margin-right: 10px;
    }

    .sidebar-message>a {
        text-decoration: none;
        color: inherit;
    }

    .sidebar-message {
        padding: 15px 20px;
    }

    .sidebar-message .media-body {
        display: block;
        width: auto;
    }

    .sidebar-message .message-avatar {
        height: 38px;
        width: 38px;
        border-radius: 50%;
    }

    .setings-item {
        padding: 15px 20px;
        border-bottom: 1px solid var(--#{$prefix}border-color);
    }
}