/* TEAMS */
.team-members {
    margin: 10px 0;
}

.team-members img.rounded-circle {
    width: 42px;
    height: 42px;
    margin-bottom: 5px;
}
