//
//
//

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 500;
    font-family: $font-family-secondary;
}

.h1,
.h2,
.h3,
h1,
h2,
h3 {
    margin-top: 20px;
    margin-bottom: 10px;
}

.ibox-content,
.ibox-title {

    h1,
    h2,
    h3,
    h4,
    h5 {
        margin-top: 5px;
    }
}

h1 {
    font-size: 30px;
}

h2 {
    font-size: 24px;
}

h3 {
    font-size: 16px;
}

h4 {
    font-size: 14px;
}

h5 {
    font-size: 12px;
}

h6 {
    font-size: 10px;
}

h3,
h4,
h5 {
    margin-top: 5px;
}