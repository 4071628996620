/*
* Author: WebAppLayers
* Product Name: INSPINIA
* Version: 3.0.0
* Purchase: https://wrapbootstrap.com/theme/inspinia-responsive-admin-template-WB0R5L90S?ref=inspinia
* Website: https://www.webapplayers.com
* Contact: webapplayers07@gmail.com
* File: SKIN Material
*/


$md-color-primary: $primary;
$md-shadow-main: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);



body.skin-material {
    font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.skin-material {
        
    .nav-header {
        background: url("../img/patterns/4.png") no-repeat;
    }

    .nav>li.active>a {
        font-weight: 600;
    }

    .navbar-default .nav>li>a:hover,
    .navbar-default .nav>li>a:focus {
        font-weight: 500;
    }

    .nav>li>a {
        font-weight: 400;
        font-size: 14px;
    }

    .wrapper-content {
        padding: 30px 20px 40px;
    }

    @media (max-width: 768px) {
        .wrapper-content {
            padding: 30px 0 40px;
        }
    }

    .ibox {
        clear: both;
        margin-bottom: 25px;
        margin-top: 0;
        padding: 0;
        box-shadow: $md-shadow-main;
    }

    .ibox.border-bottom {
        border-bottom: none !important;
    }

    .ibox-title,
    .ibox-content {
        border-style: none;
    }

    .ibox-title h5 {
        font-size: 16px;
    }

    a.close-canvas-menu {
        color: $white;
    }

    #nestable-output,
    #nestable2-output {
        font-family: 'Roboto', lucida grande, lucida sans unicode, helvetica, arial, sans-serif;
    }

    .dashboard-header {
        border-bottom: none !important;
        border-top: 0;
        padding: 20px 20px 20px 20px;
        margin: 30px 20px 0 20px;
        box-shadow: $md-shadow-main;
    }

    @media (max-width: 768px) {
        .dashboard-header {
            margin: 20px 0 0 0;
        }
    }

    .nav-header .text-muted,
    .navbar-form-custom .form-control {
        color: $white !important;
    }

    .dropdown-menu {
        border-radius: 0 !important;
        box-shadow: $md-shadow-main;
    }

    &.mini-navbar .nav-second-level li a {
        font-size: 13px !important;
    }
}

html {
    &:not([data-bs-theme=dark]) {
        body.skin-material {
            background-color: var(--#{$prefix}secondary-bg);
        }
        
        .skin-material {
        
            ul.nav-second-level {
                background-color: inherit;
            }
        
            .page-heading {
                border-bottom: none !important;
                border-top: 0;
                padding: 0 10px 20px 10px;
                box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.34), 0 0 6px 0 rgba(0, 0, 0, 0.14);
            }

            .welcome-message {
                color: $white !important;
                font-weight: 300;
            }
        
            #top-search::-moz-placeholder {
                color: $white;
            }
        
            #top-search::-webkit-input-placeholder {
                color: $white;
            }
        
            .nav>li>a {
                color: $text-color;
            }
        
            .nav.navbar-right>li>a {
                color: $white;
            }
        
            .nav>li.active>a {
                color: darken($text-color, 5%);
            }
        
            .navbar-default .nav>li>a:hover,
            .navbar-default .nav>li>a:focus {
                color: darken($text-color, 5%);
            }
        
            .nav .open>a,
            .nav .open>a:hover,
            .nav .open>a:focus {
                background: $primary;
            }
        
            .navbar-top-links li {
                display: inline-table;
            }
        
            .navbar-top-links .dropdown-menu li {
                display: block;
            }
        
            .nav>li.active {
                background: lighten($gray, 2%);
            }
        
            .navbar-default .nav>li>a:focus,
            .navbar-default .nav>li>a:hover {
                background-color: inherit;
            }
        
            .navbar-top-links .dropdown-menu li a {
                padding: 3px 20px;
                min-height: inherit;
            }
        
            .nav-header .navbar-top a {
                color: var(--#{$prefix}secondary-bg);
            }
        
            .mini-navbar .nav-second-level {
                background-color: inherit;
            }
        
            .mini-navbar li.active .nav-second-level {
                left: 65px
            }
        
            .canvas-menu.mini-navbar .nav-second-level {
                background: inherit;
            }
        
            .navbar-top {
                background: var(--#{$prefix}secondary-bg);
                transition-duration: 0.4s;
                z-index: 2030;
                border-bottom: none !important;
            }
        
            .navbar-top {
                background-color: $md-color-primary !important;
                box-shadow: $md-shadow-main;
            }
        
            .navbar-default {
                box-shadow: $md-shadow-main;
            }
        
            // Right sidebar
        
            #right-sidebar {
                box-shadow: $md-shadow-main;
                border: none;
                z-index: 900;
            }
        
            .white-bg .navbar-top {
                background: #fff !important;
            }
        
            .contact-box {
                box-shadow: $md-shadow-main;
                border: none;
            }
        
            ul.notes li div {
        
                box-shadow: $md-shadow-main;
            }
        
            .file {
                border: none;
                box-shadow: $md-shadow-main;
            }
        
            .mail-box {
                background-color: var(--#{$prefix}secondary-bg);
                box-shadow: $md-shadow-main;
                padding: 0;
                margin-bottom: 20px;
                border: none;
            }
        
            .mail-box-header {
                border: none;
                background-color: var(--#{$prefix}secondary-bg);
                box-shadow: $md-shadow-main;
                padding: 30px 20px 20px 20px;
            }
        
            .mailbox-content {
                border: none;
                padding: 20px;
                background: var(--#{$prefix}secondary-bg);
            }
        
            .social-feed-box {
                border: none;
                background: var(--#{$prefix}secondary-bg);
                margin-bottom: 15px;
                box-shadow: $md-shadow-main;
            }
        
            &.canvas-menu.mini-navbar .nav-second-level {
                background: lighten($gray, 2%);
            }
        
            &.mini-navbar .nav-second-level {
                background-color: var(--#{$prefix}secondary-bg);
                box-shadow: $md-shadow-main;
            }
        
            &.top-navigation .nav.navbar-right>li>a {
                padding: 15px 20px;
                color: $text-color;
            }
        
            &.top-navigation .nav>li a:hover,
            .top-navigation .nav>li a:focus,
            &.top-navigation .nav .open>a,
            &.top-navigation .nav .open>a:hover,
            &.top-navigation .nav .open>a:focus {
                color: $primary;
                background: var(--#{$prefix}secondary-bg);
            }
        
            &.top-navigation .nav>li.active a {
                color: $primary;
                background: var(--#{$prefix}secondary-bg);
            }
        
            &.fixed-nav #side-menu {
                background-color: var(--#{$prefix}secondary-bg);
            }
        
            &.fixed-nav #wrapper.top-navigation #page-wrapper {
                margin-top: 0;
            }
        
            &.boxed-layout #wrapper {
                background-color: var(--#{$prefix}secondary-bg);
            }
        
            &.canvas-menu nav.navbar-default {
                z-index: 2001;
                background: var(--#{$prefix}secondary-bg);
                height: 100%;
                position: fixed;
                display: none;
            }
        }
    }
}