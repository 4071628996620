// 

.dropdown-item {
    width: auto;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: inherit;
    color: inherit;
}


.dropdown-menu {
    box-shadow: var(--#{$prefix}dropdown-box-shadow);
    animation-name: DropDownSlideUp;
    animation-duration: 0.3s;
    animation-fill-mode: both;
    will-change: transform;
    position: absolute;
    z-index: 1000;

    &[data-popper-placement="top-start"],
    &[data-popper-placement="top"] {
        animation-name: DropDownSlideDown;
    }
}

@keyframes DropDownSlideUp {
    100% {
        margin-top: 0px;
    }

    0% {
        margin-top: 3px;
    }
}

@keyframes DropDownSlideDown {
    100% {
        margin-bottom: 0;
    }

    0% {
        margin-bottom: 2px;
    }
}

.dropdown-menu>li>a {
    border-radius: 3px;
    color: inherit;
    line-height: 25px;
    text-align: left;
    font-weight: normal;
    display: block;
    padding: 3px 20px;
}


.dropdown-menu>.active>a,
.dropdown-menu>.active>a:focus,
.dropdown-menu>.active>a:hover {
    color: $white;
    text-decoration: none;
    background-color: $primary;
    outline: 0;
}

.dropdown-menu>li>a.font-bold {
    font-weight: 600;
}