//
// chartist.scss
//

.ct-golden-section:before {
    float: none;
}

.ct-grid {
    stroke: rgba($text-muted,.2);
}

.ct-label {
    fill: var(--#{$prefix}body-color);
    color: var(--#{$prefix}body-color);
    font-size: 12px;
    line-height: 1;
}

.ct-series-a {
    .ct-area,
    .ct-slice-pie {
        fill: $blue;
    }
}

.ct-series-b {
    .ct-area,
    .ct-slice-pie {
        fill: $success;
    }
}

.ct-series-c {
    .ct-area,
    .ct-slice-pie {
        fill: $warning;
    }
}

.ct-series-d {
    .ct-area,
    .ct-slice-pie {
        fill: $danger;
    }
}

.ct-area {
    fill-opacity: .33;
}

.chartist-tooltip {
    position: absolute;
    display: inline-block;
    opacity: 0;
    min-width: 10px;
    padding: 2px 10px;
    border-radius: 3px;
    background: $dark;
    color: $light;
    text-align: center;
    pointer-events: none;
    z-index: 1;
    transition: opacity .2s linear;
    &.tooltip-show {
        opacity: 1;
    }
}
.ct-grid {
    stroke: rgba(145, 166, 189, 0.25);
    stroke-width: 1px;
    stroke-dasharray: 2px;
}
.ct-point {
    stroke-width: 10px;
    stroke-linecap: round;
}
.ct-line {
    fill: none;
    stroke-width: 4px;
}
.ct-area {
    stroke: none;
    fill-opacity: 0.1;
}
.ct-bar {
    fill: none;
    stroke-width: 10px;
}
.ct-slice-donut {
    fill: none;
    stroke-width: 60px;
}
.ct-series-a .ct-point,
.ct-series-a .ct-line,
.ct-series-a .ct-bar,
.ct-series-a .ct-slice-donut {
    stroke: #1AB394;
}
.ct-series-a .ct-slice-pie,
.ct-series-a .ct-area {
    fill: #1AB394;
}
.ct-series-b .ct-point,
.ct-series-b .ct-line,
.ct-series-b .ct-bar,
.ct-series-b .ct-slice-donut {
    stroke: #79D2C0;
}
.ct-series-b .ct-slice-pie,
.ct-series-b .ct-area {
    fill: #79D2C0;
}
.ct-series-c .ct-point,
.ct-series-c .ct-line,
.ct-series-c .ct-bar,
.ct-series-c .ct-slice-donut {
    stroke: #D3D3D3;
}
.ct-series-c .ct-slice-pie,
.ct-series-c .ct-area {
    fill: #D3D3D3;
}
.ct-series-d .ct-point,
.ct-series-d .ct-line,
.ct-series-d .ct-bar,
.ct-series-d .ct-slice-donut {
    stroke: #BABABA;
}
.ct-series-d .ct-slice-pie,
.ct-series-d .ct-area {
    fill: #BABABA;
}
.ct-series-e .ct-point,
.ct-series-e .ct-line,
.ct-series-e .ct-bar,
.ct-series-e .ct-slice-donut {
    stroke: #453D3F;
}
.ct-series-e .ct-slice-pie,
.ct-series-e .ct-area {
    fill: #453D3F;
}
.ct-series-f .ct-point,
.ct-series-f .ct-line,
.ct-series-f .ct-bar,
.ct-series-f .ct-slice-donut {
    stroke: #59922B;
}
.ct-series-f .ct-slice-pie,
.ct-series-f .ct-area {
    fill: #59922B;
}
.ct-series-g .ct-point,
.ct-series-g .ct-line,
.ct-series-g .ct-bar,
.ct-series-g .ct-slice-donut {
    stroke: #0544D3;
}
.ct-series-g .ct-slice-pie,
.ct-series-g .ct-area {
    fill: #0544D3;
}
.ct-series-h .ct-point,
.ct-series-h .ct-line,
.ct-series-h .ct-bar,
.ct-series-h .ct-slice-donut {
    stroke: #6B0392;
}
.ct-series-h .ct-slice-pie,
.ct-series-h .ct-area {
    fill: #6B0392;
}
.ct-series-i .ct-point,
.ct-series-i .ct-line,
.ct-series-i .ct-bar,
.ct-series-i .ct-slice-donut {
    stroke: #F05B4F;
}
.ct-series-i .ct-slice-pie,
.ct-series-i .ct-area {
    fill: #F05B4F;
}
.ct-series-j .ct-point,
.ct-series-j .ct-line,
.ct-series-j .ct-bar,
.ct-series-j .ct-slice-donut {
    stroke: #DDA458;
}
.ct-series-j .ct-slice-pie,
.ct-series-j .ct-area {
    fill: #DDA458;
}
.ct-series-k .ct-point,
.ct-series-k .ct-line,
.ct-series-k .ct-bar,
.ct-series-k .ct-slice-donut {
    stroke: #EACF7D;
}
.ct-series-k .ct-slice-pie,
.ct-series-k .ct-area {
    fill: #EACF7D;
}
.ct-series-l .ct-point,
.ct-series-l .ct-line,
.ct-series-l .ct-bar,
.ct-series-l .ct-slice-donut {
    stroke: #86797D;
}
.ct-series-l .ct-slice-pie,
.ct-series-l .ct-area {
    fill: #86797D;
}
.ct-series-m .ct-point,
.ct-series-m .ct-line,
.ct-series-m .ct-bar,
.ct-series-m .ct-slice-donut {
    stroke: #B2C326;
}
.ct-series-m .ct-slice-pie,
.ct-series-m .ct-area {
    fill: #B2C326;
}
.ct-series-n .ct-point,
.ct-series-n .ct-line,
.ct-series-n .ct-bar,
.ct-series-n .ct-slice-donut {
    stroke: #6188E2;
}
.ct-series-n .ct-slice-pie,
.ct-series-n .ct-area {
    fill: #6188E2;
}
.ct-series-o .ct-point,
.ct-series-o .ct-line,
.ct-series-o .ct-bar,
.ct-series-o .ct-slice-donut {
    stroke: #A748CA;
}
.ct-series-o .ct-slice-pie,
.ct-series-o .ct-area {
    fill: #A748CA;
}