
/* CONTACTS */

.contact-box {
    background-color: var(--#{$prefix}secondary-bg);
    border: 1px solid var(--#{$prefix}border-color);
    padding: 20px;
    margin-bottom: 20px;
}

.contact-box>a {
    color: inherit;
}

.contact-box.center-version {
    border: 1px solid var(--#{$prefix}border-color);
    padding: 0;
}

.contact-box.center-version>a {
    display: block;
    background-color: var(--#{$prefix}secondary-bg);
    padding: 20px;
    text-align: center;
}

.contact-box.center-version>a img {
    width: 80px;
    height: 80px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.contact-box.center-version address {
    margin-bottom: 0;
}

.contact-box .contact-box-footer {
    text-align: center;
    background-color: var(--#{$prefix}secondary-bg);
    border-top: 1px solid var(--#{$prefix}border-color);
    padding: 15px 20px;
}
