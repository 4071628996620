/* DATATABLES */

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
    background: transparent;
}

.dataTables_wrapper {
    padding-bottom: 30px;
}

.dataTables_length {
    float: left;
}

.dataTables_filter label {
    margin-right: 5px;
}

.html5buttons {
    float: right;
}

.html5buttons a {
    border: 1px solid var(--#{$prefix}border-color);
    background: var(--#{$prefix}secondary-bg);
    color: #676a6c;
    box-shadow: none;
    padding: 6px 8px;
    font-size: 12px;

    &:hover,
    &:focus:active {
        background-color: #eee;
        color: inherit;
        border-color: #d2d2d2;
    }
}

div.dt-button-info {
    z-index: 100;
}

@media (max-width: 768px) {
    .html5buttons {
        float: none;
        margin-top: 10px;
    }

    .dataTables_length {
        float: none;
    }
}

.dt-bootstrap4 .form-control-sm {
    height: 29px !important;
    padding: 2px 10px;
}


// DataTables
.dt-buttons {
    margin-bottom: 12px !important;
}

.dt-search {
    float: inline-end;
    margin-bottom: 12px !important;
}

.dt-paging {
    margin-top: 12px !important;
    float: inline-end;
}

.dt-info {
    margin-top: 12px;
    display: inline-block;
}


// Footable
.footable-odd {
    background-color: var(--#{$prefix}table-striped-bg);
}