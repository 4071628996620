/* ANIMATION */

.css-animation-box h1 {
    font-size: 44px;
}

.animation-efect-links a {
    padding: 4px 6px;
    font-size: 12px;
}

#animation_box {
    background-color: #f9f8f8;
    border-radius: 16px;
    width: 80%;
    margin: 0 auto;
    padding-top: 80px;
}

.animation-text-box {
    position: absolute;
    margin-top: 40px;
    left: 50%;
    margin-left: -100px;
    width: 200px;
}

.animation-text-info {
    position: absolute;
    margin-top: -60px;
    left: 50%;
    margin-left: -100px;
    width: 200px;
    font-size: 10px;
}

.animation-text-box h2 {
    font-size: 54px;
    font-weight: 600;
    margin-bottom: 5px;
}

.animation-text-box p {
    font-size: 12px;
    text-transform: uppercase;
}