// 
// 
// 




.btn-xs {
    font-size: 0.7rem;
    padding: 0.2rem 0.4rem;
}

.btn-group-sm>.btn,
.btn-sm {
    font-size: .8rem;
}

.float-e-margins .btn {
    margin-bottom: 5px;
}


.btn-w-m {
    min-width: 120px;
}

.btn-default {
    color: var(--#{$prefix}body-color);
    background: transparent;
    border: 1px solid var(--#{$prefix}border-color);
}


.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default,
.btn-default:active:focus,
.btn-default:active:hover,
.btn-default.active:hover,
.btn-default.active:focus {
    color: inherit;
    border: 1px solid var(--#{$prefix}border-color);
}

.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) inset;
}

.btn-default.disabled,
.btn-default.disabled:hover,
.btn-default.disabled:focus,
.btn-default.disabled:active,
.btn-default.disabled.active,
.btn-default[disabled],
.btn-default[disabled]:hover,
.btn-default[disabled]:focus,
.btn-default[disabled]:active,
.btn-default.active[disabled],
fieldset[disabled] .btn-default,
fieldset[disabled] .btn-default:hover,
fieldset[disabled] .btn-default:focus,
fieldset[disabled] .btn-default:active,
fieldset[disabled] .btn-default.active {
    color: #cacaca;
}


.btn-white {
    color: inherit;
    background: transparent;
    border: 1px solid var(--#{$prefix}border-color);
}

[data-bs-theme="dark"] {
    .btn-white {
        background: var(--#{$prefix}border-color);
    }
}


.btn-white:hover,
.btn-white:focus,
.btn-white:active,
.btn-white.active,
.open .dropdown-toggle.btn-white,
.btn-white:active:focus,
.btn-white:active:hover,
.btn-white.active:hover,
.btn-white.active:focus {
    color: inherit;
    border: 1px solid var(--#{$prefix}border-color);
}

.btn-white:active,
.btn-white.active {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) inset;
}

.btn-white:active,
.btn-white.active,
.open .dropdown-toggle.btn-white {
    background-image: none;
}

.btn-white.disabled,
.btn-white.disabled:hover,
.btn-white.disabled:focus,
.btn-white.disabled:active,
.btn-white.disabled.active,
.btn-white[disabled],
.btn-white[disabled]:hover,
.btn-white[disabled]:focus,
.btn-white[disabled]:active,
.btn-white.active[disabled],
fieldset[disabled] .btn-white,
fieldset[disabled] .btn-white:hover,
fieldset[disabled] .btn-white:focus,
fieldset[disabled] .btn-white:active,
fieldset[disabled] .btn-white.active {
    color: #cacaca;
}


@mixin dim-button($color) {
    box-shadow: inset 0 0 0 darken($color, 6%), 0 5px 0 0 darken($color, 6%),  0 10px 5px var(--#{$prefix}light) !important;

    &:active {
        box-shadow: inset 0 0 0 darken($color, 6%), 0 2px 0 0 darken($color, 6%), 0 5px 3px var(--#{$prefix}light) !important;
    }
}

.btn-large-dim {
    width: 90px;
    height: 90px;
    font-size: 42px;
}

button.dim {
    display: inline-block;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    padding-top: 6px;
    margin-right: 10px;
    position: relative;
    cursor: pointer;
    border-radius: 5px;
    font-weight: 600;
    margin-bottom: 20px !important;

    &:active {
        top: 3px;
    }

    &.btn-primary {
        @include dim-button($navy);
    }

    &.btn-default {
        @include dim-button($dark-gray);
    }

    &.btn-warning {
        @include dim-button($yellow);
    }

    &.btn-info {
        @include dim-button($lazur);
    }

    &.btn-success {
        @include dim-button($blue);
    }

    &.btn-danger {
        @include dim-button($red);
    }

    &:before {
        font-size: 50px;
        line-height: 1em;
        font-weight: normal;
        color: var(--#{$prefix}secondary-bg);
        display: block;
        padding-top: 10px;
    }

    &:active:before {
        top: 7px;
        font-size: 50px;
    }
}

.btn:focus {
    outline: none !important;
}


/* CIRCLE */

.img-circle {
    border-radius: 50%;
}

.btn-circle {
    width: 30px;
    height: 30px;
    padding: 6px 0;
    border-radius: 15px;
    text-align: center;
    font-size: 12px;
    line-height: 1.428571429;
}

.btn-circle.btn-lg {
    width: 50px;
    height: 50px;
    padding: 10px 16px;
    border-radius: 25px;
    font-size: 18px;
    line-height: 1.33;
}

.btn-circle.btn-xl {
    width: 70px;
    height: 70px;
    padding: 10px 16px;
    border-radius: 35px;
    font-size: 24px;
    line-height: 1.33;
}