/*Slick Carousel */

.slick-prev:before,
.slick-next:before {
    color: $navy !important;
}


// <!-- Additional style only for demo purpose -->
.slick_demo_2 .ibox-content {
    margin: 0 10px;
}