/* Nestable list */

.dd {
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 13px;
    line-height: 20px;
}

.dd-list {
    display: block;
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
}

.dd-list .dd-list {
    padding-left: 30px;
}

.dd-collapsed .dd-list {
    display: none;
}

.dd-item,
.dd-empty,
.dd-placeholder {
    display: block;
    position: relative;
    margin: 0;
    padding: 0;
    min-height: 20px;
    font-size: 13px;
    line-height: 20px;
}

.dd-handle {
    display: block;
    margin: 5px 0;
    padding: 5px 10px;
    color: var(--#{$prefix}body-color);
    text-decoration: none;
    border: 1px solid var(--#{$prefix}border-color);
    background: rgba(var(--#{$prefix}light-rgb), 0.6);
    -webkit-border-radius: 3px;
    border-radius: 3px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}

.dd-handle span {
    font-weight: bold;
}

.dd-handle:hover {
    background: var(--#{$prefix}light);
    cursor: pointer;
    font-weight: bold;
}

.dd-item>button {
    display: block;
    position: relative;
    cursor: pointer;
    float: left;
    width: 25px;
    height: 20px;
    margin: 5px 0;
    padding: 0;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    border: 0;
    background: transparent;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    font-weight: bold;
}

.dd-item>button:before {
    content: '+';
    display: block;
    position: absolute;
    width: 100%;
    text-align: center;
    text-indent: 0;
}

.dd-item>button[data-action="collapse"]:before {
    content: '-';
}

#nestable2 .dd-item>button {
    font-family: FontAwesome;
    height: 34px;
    width: 33px;
    color: var(--#{$prefix}body-color);
}

#nestable2 .dd-item>button:before {
    content: "\f067";
}

#nestable2 .dd-item>button[data-action="collapse"]:before {
    content: "\f068";
}

.dd-placeholder,
.dd-empty {
    margin: 5px 0;
    padding: 0;
    min-height: 30px;
    background: var(--#{$prefix}light);
    border: 1px dashed var(--#{$prefix}border-color);
    box-sizing: border-box;
}

.dd-empty {
    border: 1px dashed #bbb;
    min-height: 100px;
    background-color: #e5e5e5;
    background-image: linear-gradient(45deg, var(--#{$prefix}light) 25%, transparent 25%, transparent 75%, var(--#{$prefix}light) 75%, var(--#{$prefix}light)),
    linear-gradient(45deg, var(--#{$prefix}light) 25%, transparent 25%, transparent 75%, var(--#{$prefix}light) 75%, var(--#{$prefix}light));
    background-size: 60px 60px;
    background-position: 0 0, 30px 30px;
}

.dd-dragel {
    position: absolute;
    z-index: 9999;
    pointer-events: none;
}

.dd-dragel>.dd-item .dd-handle {
    margin-top: 0;
}

.dd-dragel .dd-handle {
    -webkit-box-shadow: 2px 4px 6px 0 rgba(var(--#{$prefix}light-rgb), 0.1);
    box-shadow: 2px 4px 6px 0 rgba(var(--#{$prefix}light-rgb), 0.1);
}



/**
* Nestable Extras
*/

.nestable-lists {
    display: block;
    clear: both;
    padding: 30px 0;
    width: 100%;
    border: 0;
    border-top: 2px solid var(--#{$prefix}border-color);
    border-bottom: 2px solid var(--#{$prefix}border-color);
}

#nestable-menu {
    padding: 0;
    margin: 10px 0 20px 0;
}

#nestable-output,
#nestable2-output {
    width: 100%;
    font-size: 0.75em;
    line-height: 1.333333em;
    font-family: open sans, lucida grande, lucida sans unicode, helvetica, arial, sans-serif;
    padding: 5px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}

#nestable2 .dd-handle {
    color: inherit;
    border: 1px dashed var(--#{$prefix}border-color);
    background: rgba(var(--#{$prefix}light-rgb), 0.6);
    padding: 10px;
}

#nestable2 span.label {
    margin-right: 10px;
}

#nestable-output,
#nestable2-output {
    font-size: 12px;
    padding: 25px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}