/* TOOGLE SWICH */

.onoffswitch {
    position: relative;
    width: 64px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.onoffswitch-checkbox {
    display: none;
}

.onoffswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 2px solid $primary;
    border-radius: 2px;
}

.onoffswitch-inner {
    width: 200%;
    margin-left: -100%;
    -moz-transition: margin 0.3s ease-in 0s;
    -webkit-transition: margin 0.3s ease-in 0s;
    -o-transition: margin 0.3s ease-in 0s;
    transition: margin 0.3s ease-in 0s;
}

.onoffswitch-inner:before,
.onoffswitch-inner:after {
    float: left;
    width: 50%;
    height: 20px;
    padding: 0;
    line-height: 20px;
    font-size: 12px;
    color: var(--#{$prefix}secondary-bg);
    font-family: Trebuchet, Arial, sans-serif;
    font-weight: bold;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.onoffswitch-inner:before {
    content: "ON";
    padding-left: 10px;
    background-color: $primary;
    color: var(--#{$prefix}secondary-bg);
}

.onoffswitch-inner:after {
    content: "OFF";
    padding-right: 10px;
    background-color: var(--#{$prefix}secondary-bg);
    color: #999999;
    text-align: right;
}

.onoffswitch-switch {
    width: 20px;
    margin: 0;
    background: var(--#{$prefix}secondary-bg);
    border: 2px solid $primary;
    border-radius: 2px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 44px;
    transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-switch {
    right: 0;
}

.onoffswitch-checkbox:disabled+.onoffswitch-label .onoffswitch-inner:before {
    background-color: var(--#{$prefix}body-color);
}

.onoffswitch-checkbox:disabled+.onoffswitch-label,
.onoffswitch-checkbox:disabled+.onoffswitch-label .onoffswitch-switch {
    border-color: var(--#{$prefix}body-color);
}


/* SWITCHES */
.onoffswitch {
    position: relative;
    width: 54px;
    user-select: none;
}

.onoffswitch-checkbox {
    display: none;
}

.onoffswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 2px solid $primary;
    border-radius: 3px;
    margin-bottom: 0;
}

.onoffswitch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    -moz-transition: margin 0.3s ease-in 0s;
    -webkit-transition: margin 0.3s ease-in 0s;
    -o-transition: margin 0.3s ease-in 0s;
    transition: margin 0.3s ease-in 0s;
}

.onoffswitch-inner:before,
.onoffswitch-inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 16px;
    padding: 0;
    line-height: 16px;
    font-size: 10px;
    color: var(--#{$prefix}secondary-bg);
    font-family: Trebuchet, Arial, sans-serif;
    font-weight: bold;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.onoffswitch-inner:before {
    content: "ON";
    padding-left: 7px;
    background-color: $primary;
    color: white;
}

.onoffswitch-inner:after {
    content: "OFF";
    padding-right: 7px;
    background-color: var(--#{$prefix}secondary-bg);
    color: var(--#{$prefix}body-color);
    text-align: right;
}

.onoffswitch-switch {
    display: block;
    width: 18px;
    margin: 0;
    background: var(--#{$prefix}secondary-bg);
    border: 2px solid $primary;
    border-radius: 3px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 36px;
    -moz-transition: all 0.3s ease-in 0s;
    -webkit-transition: all 0.3s ease-in 0s;
    -o-transition: all 0.3s ease-in 0s;
    transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-switch {
    right: 0;
}