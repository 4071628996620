/* ERROR & LOGIN & LOCKSCREEN*/

.middle-box {
    max-width: 400px;
    z-index: 100;
    margin: 0 auto;
    padding-top: 40px;
}

.lockscreen.middle-box {
    width: 200px;
    padding-top: 110px;
}

.loginscreen.middle-box {
    width: 300px;
}

.loginColumns {
    max-width: 800px;
    margin: 0 auto;
    padding: 100px 20px 20px 20px;
}

.passwordBox {
    max-width: 460px;
    margin: 0 auto;
    padding: 100px 20px 20px 20px;
}

.logo-name {
    color: #e6e6e6;
    font-size: 180px;
    font-weight: 800;
    letter-spacing: -10px;
    margin-bottom: 0;
}

.middle-box h1 {
    font-size: 170px;
}

.wrapper .middle-box {
    margin-top: 140px;
}

.lock-word {
    z-index: 10;
    position: absolute;
    top: 110px;
    left: 50%;
    margin-left: -470px;
}

.lock-word span {
    font-size: 100px;
    font-weight: 600;
    color: #e9e9e9;
    display: inline-block;
}

.lock-word .first-word {
    margin-right: 160px;
}



.login-panel {
    margin-top: 25%;
}