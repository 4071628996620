/* FLOT CHART  */

.flot-chart {
    display: block;
    height: 200px;
}

.widget .flot-chart.dashboard-chart {
    display: block;
    height: 120px;
    margin-top: 40px;
}

.flot-chart.dashboard-chart {
    display: block;
    height: 180px;
    margin-top: 40px;
}

.flot-chart-content {
    width: 100%;
    height: 100%;
}

.flot-chart-pie-content {
    width: 200px;
    height: 200px;
    margin: auto;
}

.flot-tick-label {
    fill: var(--#{$prefix}body-color) !important;
}


// Sparkline Chart

.jqstooltip {
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    visibility: hidden;
    background-color: $dark !important;
    color: var(--#{$prefix}white);
    border: none !important;
    text-align: left;
    white-space: nowrap;
    z-index: 10000;
    padding: 5px;
    border-radius: 3px;
}

.jqsfield {
    color: var(--#{$prefix}secondary-bg);
    font-family: $font-family-secondary !important;
    font-size: 14px !important;
}