/* Touch Spin */

.bootstrap-touchspin-postfix.input-group-addon {
    padding: inherit;

}

.bootstrap-touchspin-postfix .input-group-text {
    background-color: inherit;
    line-height: 1;
    border: none;
}

.bootstrap-touchspin-down,
.bootstrap-touchspin-up {
    background-color: transparent !important;
}


/* Touch Spin */

.bootstrap-touchspin-postfix.input-group-addon {
    padding: inherit;

}

.bootstrap-touchspin-postfix .input-group-text {
    background-color: inherit;
    line-height: 1;
    border: none;
}