/* Vote list */

.vote-item {
    padding: 20px 25px;
    background: var(--#{$prefix}secondary-bg);
    border-top: 1px solid var(--#{$prefix}border-color);
}

.vote-item:last-child {
    border-bottom: 1px solid var(--#{$prefix}border-color);
}

.vote-actions {
    float: left;
    width: 30px;
    margin-right: 15px;
    text-align: center;

}

.vote-actions a {
    color: $navy;
    font-weight: 600;
}

.vote-actions {
    font-weight: 600;
}

.vote-title {
    display: block;
    color: inherit;
    font-size: 15px;
    font-weight: 600;
    margin-top: 5px;
    margin-bottom: 5px;
}

.vote-title:hover,
.vote-title:focus {
    color: inherit;
}

.vote-info,
.vote-title {
    margin-left: 45px;
}

.vote-info,
.vote-info a {
    color: var(--#{$prefix}secondary-color);
    font-size: 12px;
}

.vote-info a {
    margin-right: 10px;
}

.vote-info a:hover {
    color: $navy;
}

.vote-icon {
    text-align: right;
    font-size: 38px;
    display: block;
    color: lighten($text-color, 50%);
}

.vote-icon.active {
    color: $navy;
}


body.body-small .vote-icon {
    display: none;
}