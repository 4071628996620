/* Forum styles  */
.forum-post-container .media {
    margin: 10px 10px 10px 10px;
    padding: 20px 10px 20px 10px;
    border-bottom: 1px solid var(--#{$prefix}border-color);
}

.forum-avatar {
    float: left;
    margin-right: 20px;
    text-align: center;
    width: 110px;
}

.forum-avatar .rounded-circle {
    height: 48px;
    width: 48px;
}

.author-info {
    color: var(--#{$prefix}secondary-color);
    font-size: 11px;
    margin-top: 5px;
    text-align: center;
}

.forum-post-info {
    padding: 9px 12px 6px 12px;
    background: var(--#{$prefix}light);
    border: 1px solid var(--#{$prefix}border-color);
}


.img-preview-sm {
    height: 130px;
    width: 200px;
}

.media-body>.media {
    background: var(--#{$prefix}light);
    border-radius: 3px;
    border: 1px solid var(--#{$prefix}border-color);
}

.forum-post-container .media-body .photos {
    margin: 10px 0;
}

.forum-photo {
    max-width: 140px;
    border-radius: 3px;

}

.media-body>.media .forum-avatar {
    width: 70px;
    margin-right: 10px;
}

.media-body>.media .forum-avatar .rounded-circle {
    height: 38px;
    width: 38px;
}

.mid-icon {
    font-size: 66px;
}

.forum-item {
    margin: 10px 0;
    padding: 10px 0 20px;
    border-bottom: 1px solid var(--#{$prefix}border-color);
}

.views-number {
    font-size: 24px;
    line-height: 18px;
    font-weight: 400;
}

.forum-container,
.forum-post-container {
    padding: 30px !important;
}

.forum-item .forum-sub-title {
    margin-left: 50px;
}

.forum-title {
    margin: 15px 0 15px 0;
}

.forum-info {
    text-align: center;
}

.forum-icon {
    float: left;
    width: 30px;
    margin-right: 20px;
    text-align: center;
}

a.forum-item-title {
    color: inherit;
    display: block;
    font-size: 15px;
    margin-bottom: 5px;
    font-weight: 600;
}

a.forum-item-title:hover {
    color: inherit;
}

.forum-icon .fa,
.forum-icon .fa-regular {
    font-size: 30px;
    margin-top: 8px;
}

.forum-item.active .fa {
    color: $primary;
}

.forum-item.active a.forum-item-title {
    color: $primary;
}

@media (max-width: 992px) {

    .forum-info {
        margin: 15px 0 10px 0;

        /* Comment this is you want to show forum info in small devices */
        display: none;
    }

    .forum-desc {
        float: none !important;
    }

}