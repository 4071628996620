/* Intimeline */

.timeline-item .date i {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    width: 30px;
    text-align: center;
    border-top: 1px solid var(--#{$prefix}border-color);
    border-bottom: 1px solid var(--#{$prefix}border-color);
    border-left: 1px solid var(--#{$prefix}border-color);
    background: var(--#{$prefix}light);
}

.timeline-item .date {
    text-align: right;
    width: 110px;
    position: relative;
    padding-top: 30px;
}

.timeline-item .content {
    border-left: 1px solid var(--#{$prefix}border-color);
    border-top: 1px solid var(--#{$prefix}border-color);
    padding-top: 10px;
    min-height: 100px;
}

.timeline-item .content:hover {
    background: var(--#{$prefix}light);
}

@media (max-width: 350px) {

    .timeline-item .date {
        text-align: left;
        width: 110px;
        position: relative;
        padding-top: 30px;
    }

    .timeline-item .date i {
        position: absolute;
        top: 0;
        left: 15px;
        padding: 5px;
        width: 30px;
        text-align: center;
        border: 1px solid var(--#{$prefix}border-color);
        background: var(--#{$prefix}light);
    }

    .timeline-item .content {
        border-left: none;
        border-top: 1px solid var(--#{$prefix}border-color);
        padding-top: 10px;
        min-height: 100px;
    }
}


/* New Timeline style */

.vertical-container {
    /* this class is used to give a max-width to the element it is applied to, and center it horizontally when it reaches that max-width */
    width: 90%;
    max-width: 1170px;
    margin: 0 auto;
}

.vertical-container::after {
    /* clearfix */
    content: '';
    display: table;
    clear: both;
}

#vertical-timeline {
    position: relative;
    padding: 0;
    margin-top: 2em;
    margin-bottom: 2em;
}

#vertical-timeline::before {
    content: '';
    position: absolute;
    top: 0;
    left: 18px;
    height: 100%;
    width: 4px;
    background: var(--#{$prefix}border-color);
}

.vertical-timeline-content .btn {
    float: right;
}

#vertical-timeline.light-timeline:before {
    background: var(--#{$prefix}border-color);
}

.dark-timeline .vertical-timeline-content:before {
    border-color: transparent var(--#{$prefix}light) transparent transparent;
}

.dark-timeline.center-orientation .vertical-timeline-content:before {
    border-color: transparent transparent transparent var(--#{$prefix}light);
}

.dark-timeline .vertical-timeline-block:nth-child(2n) .vertical-timeline-content:before,
.dark-timeline.center-orientation .vertical-timeline-block:nth-child(2n) .vertical-timeline-content:before {
    border-color: transparent var(--#{$prefix}light) transparent transparent;
}

.dark-timeline .vertical-timeline-content,
.dark-timeline.center-orientation .vertical-timeline-content {
    background: var(--#{$prefix}light);
}

@media only screen and (min-width: 1170px) {
    #vertical-timeline.center-orientation {
        margin-top: 3em;
        margin-bottom: 3em;
    }

    #vertical-timeline.center-orientation:before {
        left: 50%;
        margin-left: -2px;
    }
}

@media only screen and (max-width: 1170px) {
    .center-orientation.dark-timeline .vertical-timeline-content:before {
        border-color: transparent var(--#{$prefix}light) transparent transparent;
    }
}

.vertical-timeline-block {
    position: relative;
    margin: 2em 0;
}

.vertical-timeline-block:after {
    content: "";
    display: table;
    clear: both;
}

.vertical-timeline-block:first-child {
    margin-top: 0;
}

.vertical-timeline-block:last-child {
    margin-bottom: 0;
}

@media only screen and (min-width: 1170px) {
    .center-orientation .vertical-timeline-block {
        margin: 4em 0;
    }

    .center-orientation .vertical-timeline-block:first-child {
        margin-top: 0;
    }

    .center-orientation .vertical-timeline-block:last-child {
        margin-bottom: 0;
    }
}

.vertical-timeline-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 16px;
    border: 3px solid var(--#{$prefix}border-color);
    text-align: center;
}

.vertical-timeline-icon i {
    display: block;
    width: 24px;
    height: 24px;
    position: relative;
    left: 50%;
    top: 50%;
    margin-left: -12px;
    margin-top: -9px;
}

@media only screen and (min-width: 1170px) {
    .center-orientation .vertical-timeline-icon {
        width: 50px;
        height: 50px;
        left: 50%;
        margin-left: -25px;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        font-size: 19px;
    }

    .center-orientation .vertical-timeline-icon i {
        margin-left: -12px;
        margin-top: -10px;
    }

    .center-orientation .cssanimations .vertical-timeline-icon.is-hidden {
        visibility: hidden;
    }
}

.vertical-timeline-content {
    position: relative;
    margin-left: 60px;
    background: var(--#{$prefix}secondary-bg);
    border-radius: 0.25em;
    padding: 1em;
}

.vertical-timeline-content:after {
    content: "";
    display: table;
    clear: both;
}

.vertical-timeline-content h2 {
    font-weight: 400;
    margin-top: 4px;
}

.vertical-timeline-content p {
    margin: 1em 0;
    line-height: 1.6;
}

.vertical-timeline-content .vertical-date {
    float: left;
    font-weight: 500;
}

.vertical-date small {
    color: $navy;
    font-weight: 400;
}

.vertical-timeline-content::before {
    content: '';
    position: absolute;
    top: 16px;
    right: 100%;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-right: 7px solid var(--#{$prefix}secondary-bg);
}

@media only screen and (min-width: 768px) {
    .vertical-timeline-content h2 {
        font-size: 18px;
    }

    .vertical-timeline-content p {
        font-size: 13px;
    }

}

@media only screen and (min-width: 1170px) {
    .center-orientation .vertical-timeline-content {
        margin-left: 0;
        padding: 1.6em;
        width: 45%;
    }

    .center-orientation .vertical-timeline-content::before {
        top: 24px;
        left: 100%;
        border-color: transparent;
        border-left-color: var(--#{$prefix}secondary-bg);
    }

    .center-orientation .vertical-timeline-content .btn {
        float: left;
    }

    .center-orientation .vertical-timeline-content .vertical-date {
        position: absolute;
        width: 100%;
        left: 122%;
        top: 2px;
        font-size: 14px;
    }

    .center-orientation .vertical-timeline-block:nth-child(even) .vertical-timeline-content {
        float: right;
    }

    .center-orientation .vertical-timeline-block:nth-child(even) .vertical-timeline-content::before {
        top: 24px;
        left: auto;
        right: 100%;
        border-color: transparent;
        border-right-color: var(--#{$prefix}secondary-bg);
    }

    .center-orientation .vertical-timeline-block:nth-child(even) .vertical-timeline-content .btn {
        float: right;
    }

    .center-orientation .vertical-timeline-block:nth-child(even) .vertical-timeline-content .vertical-date {
        left: auto;
        right: 122%;
        text-align: right;
    }

    .center-orientation .cssanimations .vertical-timeline-content.is-hidden {
        visibility: hidden;
    }
}